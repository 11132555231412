import NProgress from "nprogress";
import "nprogress/nprogress.css";
// loading插件
import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/wisdom",
    name: "wisdom",
    component: () => import("../views/wisdom.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact.vue"),
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import("../views/shop.vue"),
  },
  {
    path: "/assistant",
    name: "assistant",
    component: () => import("../views/assistant.vue"),
  },
  {
    path: "/flyfishoutlets",
    name: "flyfishoutlets",
    component: () => import("../views/flyfishoutlets.vue"),
  },
  {
    path: "/commerce",
    name: "commerce",
    component: () => import("../views/commerce.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start(); // 显示进度条
  next();
});

router.afterEach(() => {
  NProgress.done(); // 完成进度条
});
export default router;
